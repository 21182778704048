<template>
    <app-page>
        <template #page-header>
            <page-header name="State of Services" :loading="loading" no-search></page-header>
        </template>
        <b-overlay :show="loading" rounded="sm">
            <b-tabs class="main-tabs" content-class="mt-3" align="center" pills>
                <b-tab title="Pods">
                    <b-list-group class="compact">
                        <b-list-group-item v-for="pod in pods" :key="pod.name" class="text-left">
                            <router-link :to="'/pods/'+pod.projectName">{{ pod.name }}</router-link>
                            <b-badge variant="light" class="float-right m-1" :title="$moment(pod.startTime).format('YYYY-MM-DD HH:mm')">{{ pod.startTime|dateAgo }}</b-badge>
                            <b-badge :variant="pod.restarts >= 3 ? 'danger' : 'success'" class="float-right m-1">Restarts: {{ pod.restarts|number }}</b-badge>
                            <b-badge variant="success" class="float-right m-1" v-if="pod.status === 'Running'">{{ pod.status }}</b-badge>
                            <b-badge variant="danger" class="float-right m-1" v-else>{{ pod.status }}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-tab>
                <b-tab title="Events">
                    <b-table responsive="md" stacked="sm" borderless hover :fields="fields" :items="events"></b-table>
                </b-tab>
            </b-tabs>
        </b-overlay>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `state-of-services`,
    data() {
        return {
            loading: true,
            pods: [],
            events: [], fields: [
                {
                    key: `lastSeen`,
                    label: `LAST SEEN`
                }, {
                    key: `type`,
                    label: `TYPE`
                }, {
                    key: `reason`,
                    label: `REASON`
                }, {
                    key: `object`,
                    label: `OBJECT`
                }, {
                    key: `message`,
                    label: `MESSAGE`
                }
            ],
            eventsLoaded: false,
            eventsWebsocket: null
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        async init() {
            this.loading = true;
            this.events = [];
            this.eventsLoaded = false;

            if (this.eventsWebsocket) {
                this.eventsWebsocket.send(`eyein_abort`);
                this.eventsWebsocket.close();
            }

            const resp = await Network.get(`/api/pods/${this.$store.state.cluster}`);
            this.pods = resp.data;

            await this.loadEvents();

            this.loading = false;
        },
        async loadEvents() {
            if (this.eventsLoaded) {
                return;
            }

            this.eventsLoaded = true;

            this.eventsWebsocket = await Network.websocketConnect(`/api/events/logs/${this.$store.state.cluster}`);
            this.eventsWebsocket.onmessage = event => {
                this.events.push(JSON.parse(event.data));
            };
        }
    }
}
</script>

<style scoped>

</style>
